.snn-view-abstract-header {
  color: white;
  height: fit-content;
  align-items: center;
  display: flex;
  grid-gap: 25px;
  padding: 25px;
  border-radius: 5px;
}

.snn-abstract-view-page-wrapper {
  background: none;
}

.snn-view-abstract-header img {
  height: 100px;
  margin-right: 25px;
}

.snn-view-abstract-header-title-wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.snn-view-abstract-header-title {
  font-weight: 400;
  font-size: 1.5rem;
}

.snn-view-abstract-header-info-wrapper {
  display: flex;
  justify-content: space-between;
}
