.snn-topic-section {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;
  align-items: flex-start;
}

.snn-topic-section div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
}

.snn-topic-section div img {
  width: 80%;
}

@media screen and (min-width: 1024px) {
  .snn-social-wrapper {
    text-align: center;
  }

  .snn-topic-section {
    grid-template-columns: repeat(5, 1fr);
  }

  .snn-topic-section div {
    transition: transform 200ms ease-in-out;
    cursor: pointer;
  }

  .snn-topic-section div:hover {
    transform: scale(1.02);
  }
}
