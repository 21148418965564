.snn-back-button {
  letter-spacing: -0.003em;
  line-height: 32px;
  font-size: 20px;
  font-weight: 400;
  text-align: center;
  color: #225aa9;
  text-decoration: none;
}

.snn-back-button:hover {
  cursor: pointer;
}

@media screen and (min-width: 1024px) {
  .snn-topic-description {
    display: flex;
  }

  .snn-logo-wrapper {
    min-width: 200px;
    max-width: 30%;
    margin-right: 2rem;
  }

  .snn-topic-description {
    letter-spacing: -0.003em;
    line-height: 32px;
    font-size: 20px;
    font-weight: 400;
  }
}
