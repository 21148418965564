.snn-hotel-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.snn-hotel-wrapper .snn-title {
  margin-top: 0;
}

.snn-hotel-image {
  flex: 1;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.snn-email-link {
  text-decoration: none;
  color: #225aa9;
}

.snn-hotel-image img {
  width: 100%;
}

.snn-hotel-description {
  flex: 1;
  padding: 0 1rem 1rem;
}
