.snn-footer-wrapper {
  background-color: #efeeea;
  color: #403b3b;
}

.media {
  text-align: center;
}

.media h2 {
  display: none;
}

.snn-footer-section {
  margin-top: 2rem;
}

.snn-footer-section a img {
  width: 35px;
  margin-right: 1rem;
}

.snn-section-title {
  font-weight: 400;
  margin: 1rem;
}

.snn-section-item {
  font-weight: 100;
  margin: 1rem;
  cursor: pointer;
}

.snn-footer-logo-wrapper {
  margin-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-logo-wrapper-svg {
  width: 300px;
}

@media screen and (min-width: 768px) {
  .snn-footer-wrapper {
    display: flex;
    padding: 1rem;
  }

  .snn-footer-logo-wrapper {
    margin-right: 1rem;
  }

  .snn-footer-section-wrapper {
    display: flex;
    flex: 1;
  }

  .snn-footer-section {
    margin-top: 0;
  }

  .snn-section-title {
    margin-top: 0;
    display: block !important;
  }
  .snn-margin-top {
    margin-top: 1rem;
  }

  .snn-footer-section a img {
    width: 35px;
    margin-right: 1rem;
  }
}
