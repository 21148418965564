.snn-table-fees {
  text-align: center;
  margin: 5rem auto;
}

.snn-table-fees th {
  font-weight: 200;
  font-size: 1.5rem;
}

.table-fees tbody tr:nth-child(odd) {
}

.snn-prices-cell {
  font-weight: 600;
}

@media screen and (min-width: 1024px) {
  .snn-table-fees {
    width: 70%;
  }
}
