.snn-abstract-registered-card {
  padding: 15px;
  margin-bottom: 15px;
  border-radius: 5px;
  transition: transform 0.3s ease-in-out;
  color: white;
}

.snn-user-home-abstract-bio {
  background-color: #5f843e;
  border-color: #b1d39b !important;
}

.snn-user-home-abstract-advanced {
  background-color: #902138;
  border-color: #c68a8b !important;
}

.snn-user-home-nanophotonic {
  background-color: #2d8083;
  border-color: #a1bec2 !important;
}

.snn-abstract-registered-card-text {
  display: flex;
  align-items: center;
}

.snn-abstract-registered-card-text .snn-user-home-main-content-large-semi {
  margin-bottom: 0;
  flex: 1;
}

.snn-abstract-registered-card-buttons-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: 15px;
}

.snn-abstract-registered-card-button {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  transition: transform 0.3s ease-in-out;
}

.snn-abstract-registered-card:hover {
  cursor: pointer;
  transform: scale(1.01);
}

.snn-abstract-registered-card-button:hover {
  cursor: pointer;
  transform: scale(1.1);
}

.snn-abstract-registered-card-button-delete {
  margin-right: 0;
}

.snn-user-home-catalysis {
  background-color: #8b5529;
  border-color: #a87c53 !important;
}

.snn-user-home-innovation {
  background-color: #5a3680;
  border-color: #967098 !important;
}
