.snn-proceeding-wrapper {
  padding: 1rem;
  background-color: #f1f1f2;
  border-radius: 3px;
  border: solid 1px #f0f0f0;
  text-align: center;
}

.snn-proceeding-wrapper a {
  padding: 1rem;
  text-decoration: none;
  color: rgb(61, 61, 61);
  transition: color ease-in-out 200ms;
}

.snn-proceeding-wrapper a:hover {
  color: rgb(79, 183, 227);
}
