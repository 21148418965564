.snn-user-home-main-wrapper {
  display: flex;
  height: 100%;
  flex-direction: column;
  overflow-y: auto;
  /*flex-direction: column-reverse;*/
}

.snn-user-home-main-content-header {
  font-weight: 300;
  margin-bottom: 10px;
  margin-top: 0;
  font-size: 1.5em;
}

.snn-user-home-main-content-large-semi {
  margin-top: 0;
  font-weight: 600;
  margin-bottom: 25px;
}

.snn-user-homa-main-content-text {
  margin-top: 0;
  margin-bottom: 15px;
}

.snn-user-home-main-content-submit-ribbon {
  /*background-color: #192352;*/
  margin-bottom: 25px;
  color: white;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  padding: 15px 0 15px 15px;
  background: radial-gradient(circle at right, rgba(62, 65, 80, 1) 0%, rgba(25, 35, 82, 1) 40%);
}

.snn-user-home-main-content-submit-ribbon-content {
  flex: 1;
}

.snn-user-home-main-content-submit-ribbon img {
  width: 85px;
}

.snn-user-home-main-content-submit-ribbon-button {
  background-color: #75bf42;
  width: fit-content;
  padding: 5px;
  border-radius: 5px;
  display: flex;
  align-items: center;
}

.snn-user-home-speakers-wrapper {
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
}

.snn-user-home-main-speaker-wrapper {
  margin-right: 10px;
  position: relative;
}

.snn-user-home-main-speaker-wrapper:last-of-type {
  margin-left: 0;
}

.snn-user-home-main-content-submit-ribbon-button span {
  margin-left: 15px;
}

.snn-user-home-main-speaker-wrapper-speaker {
  border-radius: 50%;
  width: 55px;
  transition: transform 0.3s ease-in-out;
}

.snn-user-home-main-speaker-wrapper-speaker:hover {
  cursor: pointer;
  transform: scale(1.05);
}

.snn-user-home-main-speaker-wrapper-speaker:hover + .snn-user-home-main-speaker-wrapper-topic {
  transform: scale(1.1);
}

.snn-user-home-main-speaker-wrapper-topic {
  width: 20px;
  position: absolute;
  bottom: 0;
  right: 0;
  transition: transform 0.3s ease-in-out;
}

.snn-user-home-main-content-submit-ribbon .snn-user-home-main-content-large-semi {
  margin-bottom: 20px;
  margin-top: 15px;
}

.snn-user-home-main-content-small-text {
  font-size: 0.7rem;
  margin: 0;
}

.snn-user-home-main-content {
  flex: 1;
  padding: 15px 25px 25px;
  overflow-y: auto;
}

.snn-top-user-menu-wrapper {
  margin-top: 25px;
  min-height: 50px;
  display: grid;
  align-items: center;
  grid-template-columns: repeat(5, 1fr);
  text-align: center;
}

.snn-user-home-section {
  margin-bottom: 25px;
}

.snn-user-home-abstract-container {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  margin-right: -25px;
}

.snn-user-home-abstract-image-wrapper {
  text-align: right;
}

.snn-user-home-abstract-wrapper {
  border: black solid 1px;
  border-radius: 5px;
  padding: 10px;
  min-width: 45%;
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  color: white;
}

.snn-user-home-abstract-wrapper-text {
  flex: 1;
}

.snn-top-user-menu-wrapper div:first-of-type {
  text-align: left;
}

.snn-top-user-menu-wrapper div:last-of-type {
  text-align: right;
}

.snn-user-home-abstract-wrapper:last-of-type {
  margin-right: 0;
}

.snn-user-menu-wrapper {
  z-index: 5;
  min-height: 50px;
  /*background-color: #f6f6f6;*/
  display: grid;
  align-items: center;
  grid-template-columns: repeat(5, 1fr);
  text-align: center;
  box-shadow:
    0 -3px 6px rgba(0, 0, 0, 0.16),
    0 -3px 6px rgba(0, 0, 0, 0.23);
}

.snn-user-menu-wrapper > div {
  display: flex;
  flex-direction: column;
}

.snn-user-menu-icon {
}

.snn-user-menu-text {
  font-size: 0.7rem;
  margin: 0;
}

.snn-user-menu-highlight {
  background-color: #d9af42;
  height: 125%;
  border-radius: 5px;
  color: white;
}

.hide-on-mobile {
  display: none;
}

.gold-text {
  color: #ad8525;
}

.snn-top-user-menu-wrapper div:last-of-type .snn-user-menu-icon {
  margin-right: 0;
}

@media (min-width: 600px) {
  .snn-user-home-main-content-large-semi {
    font-size: 1.2rem;
  }

  .snn-user-menu-wrapper {
    display: none;
  }

  .snn-user-home-abstract-wrapper {
    max-width: 250px;
    min-width: 50px;
    min-height: 200px;
    transition: transform 0.3s ease-in-out;
  }

  .snn-user-home-abstract-wrapper:hover {
    cursor: pointer;
    transform: scale(1.05);
  }

  .snn-user-home-main-content-small-text {
    font-size: 1rem;
  }

  .snn-user-home-main-content {
    padding: 25px;
    width: 100%;
    margin: 0 auto;
    overflow-y: unset;
    flex: unset;
  }

  .snn-user-home-main-content-grid {
    display: grid;
    grid-gap: 25px;
    grid-template-columns: repeat(3, 1fr);
  }

  .plenary-grid {
    grid-column: 1/3;
  }

  .plenary-grid .snn-user-home-speakers-wrapper {
    grid-template-columns: repeat(4, 1fr);
  }

  .plenary-grid .snn-user-home-main-speaker-wrapper-topic {
    width: 40px;
    bottom: -15px;
    right: -15px;
  }

  .snn-user-home-abstract-section {
    grid-column: 2 / 4;
  }

  .hide-on-desktop {
    display: none;
  }

  .snn-user-home-speakers-wrapper {
    display: grid;
    grid-gap: 25px;
    grid-template-columns: repeat(3, 1fr);
  }

  .hide-on-mobile {
    display: block;
  }

  .snn-user-home-main-speaker-wrapper {
    margin-right: 0;
  }

  .snn-user-home-main-speaker-wrapper-speaker {
    width: 100%;
    border-radius: 5px;
  }

  .snn-user-home-main-speaker-wrapper-topic {
    width: 30px;
    bottom: -10px;
    right: -10px;
  }

  .snn-user-home-main-content-submit-ribbon {
    align-items: center;
    margin-bottom: 0;
  }

  .snn-user-home-main-content-submit-ribbon .snn-user-home-main-content-large-semi {
    font-size: 2rem;
  }

  .snn-user-home-main-content-submit-ribbon-button {
    font-size: 1.4rem;
    padding: 10px;
    transition: transform 0.3s ease-in-out;
  }

  .snn-user-home-main-content-submit-ribbon-button:hover {
    cursor: pointer;
    transform: scale(1.05);
  }

  .snn-user-home-main-content-submit-ribbon img {
    width: 80%;
  }

  .snn-ribbon-img-wrapper {
    text-align: end;
    width: 30%;
  }

  .snn-user-home-main-wrapper {
    flex-direction: row;
  }

  .snn-top-user-menu-wrapper div:nth-of-type(2) {
    text-align: left;
  }

  a {
    text-decoration: none;
    color: unset;
  }

  .snn-user-home-abstract-container {
    margin-right: unset;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    overflow-x: unset;
  }

  .snn-user-home-section {
    margin-bottom: 0;
  }
}

.snn-timeline-wrapper {
  grid-column: 1 / 4;
}

.snn-timeline-wrapper img {
  width: 90%;
  margin: auto;
  display: block;
}

.snn-timeline-wrapper .snn-user-home-main-content-header {
  margin-bottom: 25px;
}
