.snn-speaker-card {
  border: solid 1px #e3e3e3;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
}

.snn-speakers-card-img {
  flex: 1;
  align-items: center;
  display: flex;
}

.snn-speakers-card-img img {
  width: 100%;
  border-radius: 5px 5px 0 0;
}

.snn-speakers-card-description {
  padding: 1rem;
  flex: 1;
}

.snn-speakers-cards-wrapper {
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  gap: 1rem;
}

.snn-keynotes-cards-wrapper {
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  gap: 1rem;
}

@media (min-width: 600px) {
  .snn-speakers-cards-wrapper {
    grid-template-columns: repeat(4, 1fr);
  }

  .snn-keynotes-cards-wrapper {
    grid-template-columns: repeat(5, 1fr);
  }
}
