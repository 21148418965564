.snn-top-menu-wrapper {
  height: 50px;
  display: flex;
  border-bottom: solid 2px #225aa9;
  padding: 1rem 1rem 0 1rem;
}

.menu-button-wrapper {
  display: flex;
  align-items: center;
  height: 45px;
  width: 45px;
  justify-content: center;
}

.snn-top-menu-logo-wrapper {
  flex: 1;
  margin-right: 1rem;
  display: flex;
  align-items: center;
}

.snn-top-menu-logo {
  max-height: 45px;
}

@media screen and (min-width: 768px) {
  .snn-top-menu-logo-wrapper {
    flex: 1;
    margin-right: 1rem;
    display: flex;
    align-items: center;
  }

  .snn-top-menu-logo {
    max-height: 45px;
  }
}

.snn-top-menu-options-wrapper {
  position: static;
}

.snn-top-menu-options {
  position: fixed;
  top: 55px;
  left: 0;
  right: 0;
}

.snn-top-menu-options a {
  text-decoration: none;
  color: #225aa9;
}

.snn-top-menu-option {
  text-align: center;
  cursor: pointer;
  background-color: #fffffff0;
}

.hide-cellphone {
  display: none;
}

@media screen and (min-width: 1024px) {
  .hide-cellphone {
    display: unset;
  }

  .menu-button-wrapper {
    display: none;
  }

  .options-list {
  }

  .snn-top-menu-options {
    position: static;
    display: flex;
  }

  .snn-top-menu-option {
    text-align: center;
    flex-direction: row;
    background-color: transparent;
    margin-right: 1rem;
  }
}
