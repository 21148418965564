.snn-user-home-side-menu-logo-wrapper {
  width: 80%;
  margin: 50px auto 15px;
}

.snn-user-home-side-menu-options-wrapper {
  position: relative;
}

.snn-user-home-side-menu-wrapper-not-expanded {
  width: 5%;
  height: 100%;
  background-color: #f6f6f6;
  position: sticky;
  top: 0;
  transition: width 0.3s ease-in-out;
}

.snn-user-home-side-menu-wrapper-not-expanded .snn-user-home-side-menu-logo-wrapper {
  width: 0%;
}

.snn-user-home-side-menu-wrapper-not-expanded .snn-user-home-side-menu-option-wrapper {
  display: grid;
  grid-gap: 10px;
  justify-content: center;
}

.snn-user-home-side-menu-wrapper-not-expanded .snn-user-home-side-menu-option-wrapper span {
  display: block;
  text-align: center;
  width: unset;
  margin-right: 0;
}

.snn-user-home-side-menu-wrapper-not-expanded .snn-user-home-main-content-small-text {
  font-size: 0.8rem;
  text-align: center;
}

.snn-user-home-side-menu-wrapper-not-expanded hr {
  border: none;
  margin-top: 35px;
}

.snn-user-home-side-menu-wrapper {
  width: 20%;
  height: 100%;
  background-color: #f6f6f6;
  position: sticky;
  top: 0;
  transition: width 0.3s ease-in-out;
}

.snn-user-home-side-menu-hide {
  bottom: -150px;
  position: absolute;
  right: -10px;
  background-color: #f6f6f6;
  border-radius: 50%;
  transition: right 0.3s ease-in-out;
}

.snn-user-home-side-menu-hide:hover {
  cursor: pointer;
  right: -15px;
}

snn-user-home-side-menu-logo-wrapper .snn-top-menu-logo {
  max-height: 800px;
}

.snn-user-home-side-menu-wrapper hr {
  border-top-style: solid;
  border-top-color: #cacaca;
  width: 20%;
  border-radius: 50%;
  margin: 25px auto;
}

.snn-user-home-side-menu-option-wrapper {
  width: 80%;
  margin: 0 auto 15px;
  display: flex;
  align-items: center;
  transition: color 0.3s ease-in-out;
}

.snn-user-menu-icon {
  width: 20px;
  margin-right: 15px;
}

.snn-user-home-side-menu-option-wrapper:hover {
  color: #ad8525;
  cursor: pointer;
}
